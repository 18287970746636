<template>
  <div>
    <div class="mb-5 pa-3 primary white--text">
      {{ formatDateTime(item.start_date, "DD/MM/YYYY") }} -
      {{ formatDateTime(item.end_date, "DD/MM/YYYY") }}:
      {{ item.warehouse_code }} - {{ item.tracking_id }}
    </div>

    <v-alert v-if="msg" color="error" text dense border="left">
      <div class="fs-14">
        {{ msg }}
      </div>
    </v-alert>

    <v-alert
      color="success"
      text
      dense
      border="left"
      v-if="isScannedLocation && nextLocations.length === 0"
    >
      <div class="fs-14">
        {{ $t("labels.full_position") }}
      </div>
    </v-alert>
    <v-alert color="warning" text dense border="left" v-else>
      <div class="fs-14">
        {{ $t("labels.next_position") }}:
        {{ locationTxt }}
      </div>
    </v-alert>

    <input-qr-scan-model
      ref="inputQr_1"
      v-model="cell_id"
      class="mb-3"
      :label="$t('labels.position')"
      @keyupEnter="scanPosition"
    ></input-qr-scan-model>

    <input-qr-scan-model
      ref="inputQr_2"
      v-model.number="quantity"
      class="mb-3"
      :label="$t('labels.quantity')"
      @keyupEnter="scanQuantity"
      type="number"
    ></input-qr-scan-model>

    <input-qr-scan-model
      ref="inputQr_3"
      v-model="code"
      class="mb-3"
      :label="$t('labels.sku')"
      @keyupEnter="scanSku"
    ></input-qr-scan-model>

    <v-btn block color="success" :disabled="isDisabled" @click="scanSku">{{
      $t("labels.confirm")
    }}</v-btn>
  </div>
</template>

<script>
import { formatDateTime, formatNumber } from "@/libs/helpers";
import { httpClient } from "@/libs/http";

export default {
  name: "Process",
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    isLoading: false,
    cell_id: null,
    quantity: null,
    code: null,
    msg: null,
    isScannedLocation: false,
    nextLocations: [],
  }),
  computed: {
    isDisabled() {
      return (
        !this.cell_id ||
        (!this.quantity && this.quantity != 0) ||
        this.quantity < 0 ||
        (!this.code && this.quantity != 0)
      );
    },
    locationTxt() {
      if (!this.nextLocations || this.nextLocations.length === 0) {
        return "";
      }
      return this.nextLocations.map((location) => location.cell_id).join("; ");
    },
  },
  methods: {
    formatDateTime,
    formatNumber,
    focusInput(ref) {
      setTimeout(() => {
        this.$refs[ref].focusInput();
      }, 100);
    },
    async scanPosition() {
      if (this.isLoading) {
        this.isLoading = false;
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        const { data } = await httpClient.post("/inventory/v1/scan-position", {
          cell_id: this.cell_id,
          id: this.item.id,
        });
        this.msg = data.msg;
        this.nextLocations = [...data.nextLocations];
        this.isScannedLocation = true;
        this.isLoading = false;
        if (!this.msg) {
          this.focusInput("inputQr_2");
          document.getElementById("success_sound_player").play();
        } else {
          this.cell_id = null;
          document.getElementById("error_sound_player").play();
        }
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.cell_id = null;
        this.isLoading = false;
        document.getElementById("error_sound_player").play();
      }
    },
    async scanQuantity() {
      this.focusInput("inputQr_3");
      document.getElementById("success_sound_player").play();
    },
    async scanSku() {
      if (this.isDisabled) {
        return false;
      }

      if (this.isLoading) {
        this.isLoading = false;
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      let sku = this.code;
      if (this.code && this.code.includes("@")) {
        const codeSplit = this.code.split("@");
        sku = codeSplit[0];
      }
      try {
        await httpClient.post("/inventory/v1/confirm", {
          cell_id: this.cell_id,
          id: this.item.id,
          quantity: this.quantity,
          sku,
        });
        this.resetAll();
        this.isLoading = false;
        document.getElementById("success_sound_player").play();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.code = null;
        this.isLoading = false;
        document.getElementById("error_sound_player").play();
      }
    },
    resetAll() {
      this.code = null;
      this.quantity = null;
      this.cell_id = null;
      this.msg = null;
      this.focusInput("inputQr_1");
    },
  },
};
</script>
